const style = theme => ({
    button: {
      color:'#fff',
      backgroundColor: '#9d002c',
      marginRight: "1.5rem",
      marginLeft: "1.5rem",
      '&:hover': {
        color: '#fff',
        backgroundColor: "#89000e",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.4),0px 1px 1px 0px rgba(0,0,0,0.34),0px 1px 3px 0px rgba(0,0,0,0.32);"
      },
    },
    text: {
      width: '100%',
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingTop: "1rem",
    },
    article:  {
      paddingBottom: "1rem",
      marginBottom: "2rem",
      borderRadius: ".25rem",
      display: 'flex; display: -webkit-flex; display: -ms-flexbox',
      flexWrap: 'wrap',
      justifyContent: 'end',
      WebkitBoxPack: 'end',
      MsFlexPack: 'end',
      overflow: 'hidden',
    },
    gridImage: {
      width: '100%',
      height: 'auto',
      overflowX: "auto",
      // maxHeight: 200,
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      WebkitTransform: 'translateZ(0)',
    }
})

export {
    style,
}