import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import {
  style,
  ExpansionPanelStyle,
  ExpansionPanelDetailsStyle,
  ExpansionPanelSummaryStyle,
} from "./ControlledExpansionPanelsStyle"

const useStyles = makeStyles(style);
const ExpansionPanel = withStyles(ExpansionPanelStyle)(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles(ExpansionPanelSummaryStyle)(MuiExpansionPanelSummary);
const ExpansionPanelDetails = withStyles(ExpansionPanelDetailsStyle)(MuiExpansionPanelDetails);

const Summary = (props) => {
  const H = props.classesH;
  const P = props.classesP;
  const title = props.title;
  const description = props.description;
  return (
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <h6 className={H}> {title} </h6>
      <p className={P}> {description} </p>
    </ExpansionPanelSummary>
  )
}

const Details = (props) => {
  const text = props.text;
  const classes = props.classes;

  if (!text) return "";

  if (typeof(text) == 'string') {
    return (
      <p className={classes}>
        {text.split(":")[0]} {text.split(":")[1]}
      </p> 
    );
  }
  return <p className={classes} > {text} </p> ;
}

const FileButton = (props) => {
  return (
    <Button 
      key={props.text} 
      variant="contained" 
      color="secondary" 
      className={props.classes} 
      href={props.url} 
      target='_blank'
    > 
      {props.text}
    </Button>
  )
}

const PanelDetails = (props) => {
  return (
    <ExpansionPanelDetails>
      <Details classes={props.classesD} text={props.text} />
      {props.files ? (
        props.files.map(file => {
          return ( <FileButton text={file.text} classes={props.classesB} url={file.url}/> )
        }) 
      ) : ''}
    </ExpansionPanelDetails>
  )
}


const ControlledExpansionPanels = ({panels}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const createPanel = (panel, i) => {
    return (
      <ExpansionPanel 
        square key={i} 
        expanded={expanded === 'panel'+i} 
        onChange={handleChange('panel'+i)} 
        TransitionProps={{ unmountOnExit: true }}
      >
        <Summary 
          classesH={classes.heading} 
          classesP={classes.secondaryHeading} 
          title={panel.title} 
          description={panel.description ? panel.description : ''} 
        />
        <PanelDetails 
          classesD = {classes.details}
          classesB = {classes.button}
          text = {panel.text}
          files = {panel.files}
        />
      </ExpansionPanel>
    )
  }

  const createPanels = () => {
    var panelsList = []

    for (var i=0; i<panels.length; i++) 
      panelsList.push(createPanel(panels[i], i))

    return panelsList
  }

  return (
    <div>
      {createPanels()}
    </div>
  );
}

export default ControlledExpansionPanels;