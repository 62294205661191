const data = [
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/cancEventi.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/tabellari/cancEventi.jpg',
    thumbnailWidth: 208,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/cauzioniFidejussioni.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/cauzioniFidejussioni.jpg',
    thumbnailWidth: 269,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/clinicalTrial.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/clinicalTrial.jpg',
    thumbnailWidth: 493,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/contingency.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/contingency.jpg',
    thumbnailWidth: 533,
    thumbnailHeight: 300,
  },{
    src: process.env.PUBLIC_URL+'/media/tabellari/eo.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/eo.png',
    thumbnailWidth: 493,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/infedeltaDipendenti.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/infedeltaDipendenti.png',
    thumbnailWidth: 533,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/istitutiVigilanza.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/istitutiVigilanza.jpg',
    thumbnailWidth: 208,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/easy.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/easy.png',
    thumbnailWidth: 1104,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/pubblicaAmministrazione.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/pubblicaAmministrazione.jpg',
    thumbnailWidth: 441,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/RCTOmail.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/RCTOmail.png',
    thumbnailWidth: 493,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/recallDelProdotto.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/recallDelProdotto.png',
    thumbnailWidth: 534,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/responsabilitaCivile.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/responsabilitaCivile.png',
    thumbnailWidth: 533,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/riepilogoPrestazioni.png',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/riepilogoPrestazioni.png',
    thumbnailWidth: 208,
    thumbnailHeight: 300,
  },
  {
    src: process.env.PUBLIC_URL+'/media/tabellari/stabilizzazioneFatturato.jpg',
    thumbnail: process.env.PUBLIC_URL+'/media/tabellari/stabilizzazioneFatturato.jpg',
    thumbnailWidth: 208,
    thumbnailHeight: 300,
  },
]

export default data