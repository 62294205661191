import React, {useEffect} from 'react';
import NavbarChange from 'components/Navbar/NavbarChange';
import Footer from 'components/Footer/Footer.js';
import Contact from 'components/Contact/Contact.js'
import Fade from 'react-reveal/Fade'

export default function Home() {
  const [clip, setClip] = React.useState(window.innerWidth>768 ? {size: "md", point:"0,0 0,100 25,100 35,50 25,0"} : {size:"sm", point:"0,0 0,25 50,35 100,25 100,0"})

  useEffect(() => {
    const handleResize = () => {
      let width = window.innerWidth>768 ? 'md' : 'sm'
      if (width !== clip.size)  {
        setClip({size: width, point:(width === 'md' ? "0,0 0,100 25,100 35,50 25,0" : "0,0 0,25 50,35 100,25 100,0")})
      } 
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [clip])

  return (
    <React.Fragment>	
      <NavbarChange />
      <header id="header">
        <section className="view" id="intro" style={{position: "relative", backgroundImage: (window.innerWidth<768 ? "url(/media/bg_mobile_600x900.jpg)": "url(/media/bg.jpg)")}}>
          <div className="rgba-black h-100 w-100 position-absolute"> 
            <div className="row h-100 div-header m-0"> 
              <svg width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon points={clip.point}  style={{fill:'white',stroke:'none',fillRule:'evenodd'}} />
              </svg>
              <div style={{paddingTop: (clip.size==='sm' ? '3rem' : '')}} className="pr-4 pl-3 img-header position-absolute">
                <Fade right force>
                  <img className="img-fluid animated wow fadeInRight p-1 mx-auto my-auto w-100 h-100" alt='brand' src={process.env.PUBLIC_URL+"/media/brand.svg"} data-wow-delay=".5s" /> 
                </Fade>
              </div>
            </div>
            <div className="div-blockquote">
              <div className="row h-100 m-0 pl-4 pr-3">
                <blockquote className="my-auto mx-auto">
                  <Fade left force>
                    <h1 className="h1-responsive animated wow fadeInLeft" style={{wordBreak: "break-word"}} data-wow-delay="1s"><span className="">“</span>Aspire to make a difference<span className="">”</span></h1>
                  </Fade>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </header>
      <main id="content">
        <section className="text-center py-5 grey lighten-4" id="about">
          <div className="container">
            <div className="wow fadeIn">
              <h2 className="h2-responsive pt-4 pt-md-5 pb-3">Chi siamo</h2>
            
              <div className="row align-items-center">
                <div className='col col-md-7 text-left'>
                  <p className={"px-lg-5"+(window.innerWidth>992 ? ' lead' : '')}>
                    <strong>EASY Underwriting Agency Srl</strong>, costituita nel luglio 2019 ha sede legale a Milano ed è iscritta nella Sezione A del Registro Unico degli Intermediari e opera attraverso accordi di collaborazioni con Agenti e Brokers. 
                  </p>
                  <p className={"px-lg-5 py-1"+(window.innerWidth>992 ? ' lead' : '')}>
                  Nasce dall’esperienza del Managment nella gestione e sviluppo delle Reti Distributive gestendo l’intero ciclo di business dal piazzamento del rischio alla gestione del sinistro.
                  </p>
                </div>

                <div className='col col-md-5 show-md'>
                  <img className='img-fluid h-100' alt='brand' style={{maxHeight:"350px", marginBottom: '2rem'}} src={process.env.PUBLIC_URL+"/media/logo.svg"} /> 
                </div>
              </div>
              {/* <div className="row justify-content-between">
                <div className='col-12 col-md-6 text-left'>
                  <p className={"px-lg-5"+(window.innerWidth>992 ? ' lead' : '')}>
                    <strong>EASY opera con mercati assicurativi internazionali attraverso i quali possiamo offrire le seguenti soluzioni:</strong></p>
                  <ul className={"ml-md-2 px-lg-5"+(window.innerWidth>992 ? ' lead' : '')}>
                    <li>RCT/O</li>
                    <li>RC Prodotti (con Richiamo del prodotto)</li>
                    <li>Sperimentazioni Cliniche</li>
                    <li>Aviation – Droni</li>
                    <li>Contingency</li>
                    <li>Cyber</li>
                    <li>Energy</li>
                    <li>Mediatech</li>
                  </ul>  
                </div>

                <div className='col-12 col-md-6 text-left'>
                  <p className={"px-lg-5"+(window.innerWidth>992 ? ' lead' : '')}>
                  <strong>EASY STANDARD per la Responsabilità Civile Professionale:</strong></p>
                  <ul className={"ml-md-2 px-lg-5"+(window.innerWidth>992 ? ' lead' : '')}>
                    <li>Ingegneri</li>
                    <li>Architetti</li>
                    <li>Geometri</li>
                    <li>Periti Agrari</li>
                    <li>Periti Industriali</li>
                    <li>Commercialisti</li>
                    <li>Avvocati</li>
                    <li>Consulenti del Lavoro</li>
                    <li>Tributaristi</li>
                    <li>Mediatori Creditizi</li>
                    <li>Amministratori di Condominio</li>
                    <li>Agenti Immobiliari</li>
                    <li>Agenti e Broker</li>
                    <li>Professioni NON regolamentate</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <Contact />
      </main>

      <Footer />
    </React.Fragment>
    
  )
}