import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

class ScrollIntoView extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);
  componentWillUnmount = () => {}

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname) window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  render = () => this.props.children;
}

export default withRouter(ScrollIntoView);