import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ControlledExpansionPanels from './ControlledExpansionPanels/ControlledExpansionPanels'
import style from './CollapseDivStyle'

const useStyles = makeStyles(style);

function easy(str)
{
  return<span>EASY&nbsp;{str}</span>;
}

const CollapseDiv = (props) => {
  const classes = useStyles();
  return (
    <Collapse 
      className={classes.collapse} 
      in= {props.isOpen} 
      timeout="auto" 
      unmountOnExit
    >
      <div className={classes.paragraph}>
        <h5 className={ classes.title } > {easy(props.title)} </h5>
        {props.desc && <p className='text-center'> {props.desc} </p>}
        
        <div className={classes.container+" container-fluid" } >
          <ControlledExpansionPanels panels={props.sections} />
        </div>
      </div>
    </Collapse>
  )
}

export default CollapseDiv;