import React from 'react';
import Fab from '@material-ui/core/Fab'

export default function Footer() {
  return(
    <footer className="page-footer indigo darken-2 center-on-small-only pt-0 pt-md-3 mt-0">
      <div className="container py-5">
        <div className='row py-4'>
          <div className='col-12 col-lg-6 blue-grey-text pt-5 pt-lg-1'>
            <p className='my-2 text-white'><strong>Sede Legale</strong></p>
            <p className='m-0'>Via Privata del Gonfalone 3, 20123 Milano (MI)</p>
            <p className='m-0'>info@easyunderwriting.it</p>
          </div>

          <div className='col-12 col-lg-6 blue-grey-text pt-5 pt-lg-1'>
            <p className='my-2 text-white'><strong>EASY Underwriting Agency srl</strong></p>
            <p className='m-0'>Partita IVA - Codice Fiscale: 10904950960</p>
            <p className='m-0'>Numero iscrizione RUI: A000634253</p>
            <p className='m-0'>PEC: easyunderwritingagencysrl@legalmail.it</p>
          </div>
        </div>
        <div className="row  pt-4">
          <div className="col-12">
            <div className="mb-5 text-white">
              <p>Seguici su:<Fab className='mx-3 bg-info text-white' href="https://www.linkedin.com/company/easy-underwriting-agency/about/?viewAsMember=true" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin-in" style={{height:"16px", width:"16px"}}></i></Fab>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container-fluid">
          <p>Website realized by <a href="https://socrateit.com/" className='text-info 'rel="noopener noreferrer" target="_blank">SOCRATE</a></p>
        </div>
      </div>
    </footer>
  )
}