import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect} from "react";
import {HashLink as NavLink} from "react-router-hash-link"
import {
MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBNavLink
} from "mdbreact";

const useStyles = makeStyles(theme => ({
  a: {
    color: "#00244b",
    "&:hover": {
      color: '#9d002c',
      textDecoration: "none"
    }
  },
  container: {
    maxWidth: 1200
  }
}));

export default function Navbar() {
  const classes = useStyles();
  const size = React.useState(window.innerWidth>992 ? 'lg' : ' sm')
  const [style, setStyle] = React.useState({
    brand: window.innerWidth>992 ? ' d-block' : ' d-none', 
    bgColor: window.innerWidth>992 ? ' bg-white' : '',
    shadow: ' shadow'
  })
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleCollapse = () => {
    setIsOpen(isOpen ? false : true)
    openClose()
  }
  const changeStyle = () => {
    setStyle(window.innerWidth>992 ? 
    {
      brand:' d-block', 
      bgColor: ' bg-white', 
      shadow: ' shadow'} :
    {
      brand: ' d-none', 
      bgColor: '', 
      shadow: ' shadow'
    })
  }

  const close = () => {
    setIsOpen(false)
    openClose()
  }

  const openClose = () => {
    setStyle(!isOpen ? 
    {
      brand:' d-block', 
      bgColor: ' bg-white', 
      shadow: ' shadow'} :
    {
      brand: ' d-none', 
      bgColor: '', 
      shadow: ' shadow'
    })
  }

  useEffect(() => {
    const handleResize = () => {
      if ( ((window.innerWidth>992 && size !== 'lg') || (window.innerWidth<=992 && size !== 'sm')) && !isOpen)  {
        changeStyle()
      } 
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [style, isOpen, size])

  return (
    <MDBNavbar expand="lg" className={'w-100 p-0 position-fixed'+style.bgColor+(style.bgColor !== '' ? style.shadow : '')}>
      <div className={classes.container+" container-fluid"}>
        <MDBNavbarBrand>
          <MDBNavLink to='/home/'>
            <img 
              className={'brand'+style.brand} 
              alt='EASY Underwriting Agency' 
              src={process.env.PUBLIC_URL+'/media/brand.svg'} 
            />
          </MDBNavLink>
        </MDBNavbarBrand>
        
        <button 
          data-test="navbar-toggler" 
          onClick={toggleCollapse} 
          style={{outline: 'none', color: 'rgb(157, 0, 44)', backgroundColor: 'rgb(255, 255, 255)', padding:'1rem'}} 
          type="button" 
          className={"navbar-toggler fas fa-bars m-3 btn-rounded"+(style.bgColor === '' ? style.shadow : '')}
        ></button>
        
        <MDBCollapse id="navbarCollapse" isOpen={isOpen} navbar>
          <MDBNavbarNav right>
            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/home/#about" 
                smooth 
                className={classes.a} 
                onClick={close}
              >
                Chi siamo
              </NavLink>
            </MDBNavItem>

            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/home/#contact" 
                smooth 
                className={classes.a} 
                onClick={close}
              >
                Contatti
              </NavLink>
            </MDBNavItem>

            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/news/" 
                className={classes.a} 
                onClick={close}
              >
                EASY news
              </NavLink>
            </MDBNavItem>

            <MDBNavItem>
              <NavLink 
                to="/soluzioni/" 
                className="btn px-4 py-2 btn-rounded m-2" 
                href="/soluzioni/" 
                style={{backgroundColor: "#9d002c", color:"#fff"}} 
                onClick={close}
              >
                Soluzioni
              </NavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          
        </MDBCollapse>
      </div>
    </MDBNavbar>
  );
}
