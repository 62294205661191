import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import style from './CardCollapseStyle'

const useStyles = makeStyles(style);

const CardCollapse = ({isOpen, handleClick, idChild, title, icon}) => {
  const classes = useStyles();
  
  return (
    <Card className={classes.card}>
      <CardActionArea 
        className="row" 
        onClick={handleClick}
      >
        <CardContent className={classes.content+' col-12 text-center p-0 align-text-bottom'}>
          <Icon className={classes.icon+' mx-auto mt-3'}>{icon}</Icon>
        </CardContent>
        
        <CardContent className={classes.text+" col-12 align-middle"}>
          <h5 className={classes.typography+" m-0 text-center"}>
            {title}
          </h5>
        </CardContent>
        
        <CardActions className='col-12' disableSpacing>
          <Icon
            className={clsx(classes.expand, {
              [classes.expandOpen]: isOpen,
            })}
            onClick={handleClick}
            aria-expanded={isOpen}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </Icon>
        </CardActions>    
      </CardActionArea>
    </Card>
  );
}

export default CardCollapse;