import React from 'react';
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import Gallery from 'react-grid-gallery';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Fade from "react-reveal/Fade";
import {style} from "./NewsStyle";

const useStyles = makeStyles(style);

const ImagesGas = [
  {
    src: process.env.PUBLIC_URL+'/media/articles/event_gas/gas1.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/event_gas/gas1Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
  {
    src: process.env.PUBLIC_URL+'/media/articles/event_gas/gas2.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/event_gas/gas2Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
  {
    src: process.env.PUBLIC_URL+'/media/articles/event_gas/gas3.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/event_gas/gas3Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
  {
    src: process.env.PUBLIC_URL+'/media/articles/event_gas/gas4.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/event_gas/gas4Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
]
const ImagesInte = [
  {
    src: process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img1.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img1Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
  {
    src: process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img2.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img2Thum.jpg',
    thumbnailWidth: 135,
    thumbnailHeight: 174,
  },
  {
    src: process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img3.jpg',
    thumbnail : process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img3Thum.jpg',
    thumbnailWidth: 240,
    thumbnailHeight: 174,
  },
  // {
  //   src: process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img4.jpg',
  //   thumbnail : process.env.PUBLIC_URL+'/media/articles/convegno_easy_08-10/img4Thum.jpg',
  //   thumbnailWidth: 240,
  //   thumbnailHeight: 174,
  // }
]

export default function News() {
  const classes = useStyles();
  return ( 
    <React.Fragment>
      <Navbar />

      <main className="grey lighten-5 mt-lg-5 py-4">
        <div className="container py-3" style={{overflowY: "hidden"}}>
          <Fade delay={100} duration={100}>
            <div className="text-center">
              <h2 className="h2-responsive pt-5 pb-3">EASY news</h2>
              <p className="blue-grey-text px-md-5 mb-5 pb-3 p-responsive">Resta sempre aggiornato con le notizie di EASY NEWS: articoli, interviste e nuove soluzioni assicurative o semplici curiosità dal nostro mondo!</p>
            </div>
          </Fade>
          <Fade delay={100} duration={500}>
            <article className={classes.article+" shadow"}>
              <h4 className={classes.text}>EASY si specializza con nuove soluzioni assicurative</h4>
              <p className={classes.text}>EASY Underwriting Agency, agenzia di sottoscrizione con sede legale a Milano lancia tre nuove soluzioni assicurative dedicate agli Istituti di Vigilanza e alle Aziende Farmaceutiche e Cosmetiche e al mondo delle PMI e Industrie.</p>
              <Button className={classes.button} href="https://ilbroker.it/2020/04/02/easy-si-specializza-con-nuove-soluzioni-assicurative/" rel="noopener noreferrer" target="_blank">Leggi articolo</Button>
            </article>
          </Fade>
          <Fade delay={100} duration={500}>
            <article className={classes.article+" shadow"}>
              <h4 className={classes.text}>Convegno del Gruppo Agenti SARA</h4>
              <Grid className={classes.gridImage}>
                <div className={"mx-auto"} style={{maxWidth:1010}}>
                  <Gallery images={ImagesGas} enableImageSelection={false} showLightboxThumbnails={true} backdropClosesModal={true}/>
                </div>
              </Grid>
              <p className={classes.text}>EASY Underwriting Agency ha partecipato in qualità di sponsor al Convegno del Gruppo Agenti SARA nella splendida città di Paestum. In occasione dell’evento il nostro general manager Andrea Costa ha presentato tutte le soluzioni assicurative di EASY. All’evento hanno partecipato Raffaella Salerno (Chairman of the Board of Directors & Co-Founder) e l’ing. Simone Novelli (Project and Operation Manager & Co-Founder)</p>
            </article>
          </Fade>
          <Fade delay={100} duration={500}> 
            <article className={classes.article+" shadow"}>
              <h4 className={classes.text}>"Intermediari, come vivere la centralità in un mercato omnicanale"</h4>
              <Grid className={classes.gridImage}>
                <div className="mx-auto" style={{maxWidth:650}}>
                  <Gallery images={ImagesInte} enableImageSelection={false} showLightboxThumbnails={true} backdropClosesModal={true}/>
                </div>
              </Grid>
              <p className={classes.text}>All'evento sulla distribuzione assicurativa di Insurance Connect hanno partecipato circa 300 persone: tanti i temi toccati, a partire da una verifica sul campo di quanto realmente i consumatori percepiscono e apprezzano il valore di un'offerta sempre più multicanale</p>
              <Button className={classes.button} href="https://www.insurancetrade.it/insurance/contenuti/chi_siamo/9654/intermediari-come-vivere-la-centralita-in-un-mercato-omnicanale" rel="noopener noreferrer" target="_blank">leggi articolo</Button>
            </article>
          </Fade>
          <Fade delay={100} duration={500}>
            <article className={classes.article+" shadow"}>
              <h4 className={classes.text}>Intermediari, una centralità da vivere</h4>
              <p className={classes.text}>Il giorno 8 Ottobre 2019 si terrà il convegno "intermediari, una centralità da vivere", rivolto ad agenti, broker, collaboratori e compagnie assicurative.Organizzato da Insurance Connect, Milano, Corso Magenta 61 (Palazzo delle Stelline)</p>
              <Button className={classes.button} href="https://www.insurancetrade.it/insurance/contenuti/agenda/9563/intermediari-una-centralita-da-vivere" rel="noopener noreferrer" target="_blank">Leggi articolo</Button>
            </article>
          </Fade>
          <Fade delay={100} duration={500}>
            <article className={classes.article+" shadow"}>
              <h4 className={classes.text}>EASY su Tuttointermediari</h4>
              <p className={classes.text}>Tra i co-fondatori c’è Andrea Costa, che dice: «Offriamo ai nostri intermediari (agenti
                e broker) un approccio globale in termini di capacità assicurativa puntando su
                assicuratori internazionali attraverso soluzioni “su misura” difficili da reperire
                tramite il mercato assicurativo tradizionale».</p>
              <Button className={classes.button} href="https://www.tuttointermediari.it/al-via-una-nuova-agenzia-di-sottoscrizione-easy-underwriting-agency/#post-49693" rel="noopener noreferrer" target="_blank">Leggi articolo</Button>
            </article>
          </Fade>
        </div>
      </main>
      <Footer />
    </React.Fragment>
    
  )
}
