import React, {useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { HashLink as NavLink } from 'react-router-hash-link';
import {
MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem,MDBNavLink, MDBCollapse
} from "mdbreact";

const useStyles = makeStyles(theme => ({
  a: {
    "&:hover": {
      color: '#9d002c',
      textDecoration: "none"
    }
  },
  container: {
    maxWidth: 1200
  }
}));

export default function NavbarChange() {
  const classes = useStyles();
  const [size, setSize] = React.useState(window.innerWidth>992 ? 'lg' : ' sm')
  const [style, setStyle] = React.useState({
    brand: ' d-none', 
    bgColor: '',
    shadow: ' shadow',
    text: " text-white",
    margin: window.innerWidth>992 ? " mx-xl-auto mt-lg-4 ml-auto pl-xl-5" : ''
  })
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleCollapse = () => {
    setIsOpen(isOpen ? false : true)
    openClose()
  }
  const changeStyle = () => {
    setStyle(window.innerWidth>992 ? 
    {
      brand:' d-block', 
      bgColor: ' bg-white', 
      shadow: ' shadow',
      text: " blue-text",
      margin: ''
    } :
    {
      brand: ' d-none', 
      bgColor: '', 
      shadow: ' shadow',
      text: " blue-text",
      margin: ''
    })
  }

  const openClose = () => {
    setStyle(!isOpen ? 
    {
      brand:' d-block', 
      bgColor: ' bg-white', 
      shadow: ' shadow',
      text: " blue-text",
      margin:  ''
    } :
    {
      brand: ' d-none', 
      bgColor: '', 
      shadow: ' shadow',
      text: " blue-text",
      margin: ''
    })
  }

  const scrollTop = () => {
    let scrollTopP = window.pageYOffset
    setStyle((scrollTopP>125 && window.innerWidth>992)? 
      {
        brand:' d-block', 
        bgColor: ' bg-white', 
        shadow: ' shadow',
        text: " blue-text",
        margin: ""
      } :
      {
        brand: ' d-none', 
        bgColor: '', 
        shadow: ' shadow',
        text: " text-white",
        margin: ' mx-xl-auto mt-lg-4 ml-auto pl-xl-6'
      }
    )
  }
  
  const close = () => {
    setIsOpen(false)
    openClose()
  }

  useEffect(() => {
    const handleResize = () => {
      if ((window.innerWidth>992 && size !== 'lg') || (window.innerWidth<=992 && size !== 'sm'))  {
        changeStyle()
        setSize(window.innerWidth>992 ? 'lg' : ' sm')
      } 
    }
    
    const handleScroll = () => {
      if (!isOpen)
        scrollTop()
    }
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [size,style,isOpen])


  return (
    <MDBNavbar expand="lg" className={'w-100 p-0 position-fixed'+style.bgColor+(style.bgColor !== '' ? style.shadow : '')}>
      <div className={classes.container+" container-fluid"}>
        <MDBNavbarBrand>
          <MDBNavLink to='/home/'>
            <img className={'brand'+style.brand} alt='EASY Underwriting Agency' src={process.env.PUBLIC_URL+'/media/brand.svg'}  />
          </MDBNavLink>
        </MDBNavbarBrand>

        <button 
          data-test="navbar-toggler" 
          onClick={toggleCollapse} 
          style={{outline:'none', color: 'rgb(157, 0, 44)', backgroundColor: 'rgb(255, 255, 255)', padding:'1rem'}} 
          type="button" 
          className={"navbar-toggler m-3 fas fa-bars btn-rounded"+ (style.bgColor === '' ? style.shadow : '')}
        ></button>
        
        <MDBCollapse id="navbarCollapse" isOpen={isOpen} navbar>
          <MDBNavbarNav right className={style.margin}>
            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/home/#about" 
                smooth 
                className={classes.a+style.text} 
                onClick={close}
              >
                Chi siamo
              </NavLink>
            </MDBNavItem>

            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/home/#contact" 
                smooth 
                className={classes.a+style.text} 
                onClick={close}
              >
                Contatti
              </NavLink>
            </MDBNavItem>
            
            <MDBNavItem className="my-auto p-2">
              <NavLink 
                to="/news/" 
                className={classes.a+style.text} 
                onClick={close}
              >
                EASY news
              </NavLink>
            </MDBNavItem>

            <MDBNavItem>
              <NavLink 
                to="/soluzioni/" 
                className="btn px-4 py-2 btn-rounded m-2" 
                href="/soluzioni/" 
                style={{backgroundColor: "#9d002c", color:"#fff"}} 
                onClick={close}
              >
                Soluzioni
              </NavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          
        </MDBCollapse>
      </div>
      
    </MDBNavbar>
  );
}

