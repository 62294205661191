import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import LoadingScreen from 'react-loading-screen';
import ScrollIntoView from './components/ScrollIntoView/ScrollIntoView'
import ComingSoon from './pages/ComingSoon/ComingSoon'
import Home from './pages/Home/Home'
import News from './pages/News/News'
import Solution from './pages/Solutions/Solutions'
import Tabellari from './pages/Tabellari/Tabellari'
import './App.css';

const url= '/status.json'

function App() {
  const [rootRedirect, setRootRedirect] = useState(false)
  var isLoaded = false
  async function fetchUrl() {
    const response = await fetch(url);
    const json = await response.json();    
    setRootRedirect(json.rootRedirect);
  }
  useEffect(() => {
    fetchUrl();
    isLoaded = true
    }, []);

  return (
    <LoadingScreen 
      loading={isLoaded}
      bgColor='#f1f1f1'
      spinnerColor='#9d002c'
    > 
      {rootRedirect && (
        <Router>
          <ScrollIntoView>
            <Switch>
              <Route path="/comingsoon/" component={ComingSoon} />
              <Route path="/home/" component={Home} />
              <Route path="/news/" component={News} />
              <Route path="/soluzioni/" component={Solution} />
              <Route path="/tabellari/" component={Tabellari} />
              <Redirect to={rootRedirect.url} />
            </Switch>
          </ScrollIntoView>
        </Router>
      )}
    </LoadingScreen>
  );
}

export default App;
