import React from 'react';

export default function ComingSoon() {
  return (
    <div style={{backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'linear-gradient(to right bottom, #9d002c, #850048, #601a58, #342459, #00244b)', height: '100vh', flexDirection: 'column'}} className="container-fluid d-flex align-item-center justify-content-center text-white">
      <article style={{textAlign: 'center'}}>
        <h1 className='h2'>AGGIORNAMENTO IN CORSO</h1>
        <p>Stiamo aggiorando il sito per offrirvi una migliore esperienza. Il sito tornerà online a breve</p>
      </article>
      <div className='position-absolute w-100 d-flex justify-content-center align-item-end' style={{bottom: '20px'}}>
        <span className='mr-1 mr-md-2'>Il team di </span><img style={{width: '120px'}} className='img-fluid h-100' alt='socrate' src={process.env.PUBLIC_URL+'/media/socrateBrand.svg'} />
      </div>
    </div>
  )
}