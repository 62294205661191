
const style = theme => ({
  collapse: {
    backgroundColor: "#fff",
    color: "#00244b",
    width: "100%",
    
  },
  paragraph: {
    padding: "1rem",
  },
  title: {
    textAlign: 'center',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 400,
    color: "#000"
  },
  text: {
    paddingTop: '1.5rem',
    paddingBottom: "1.5rem"
  },
  container: {
    maxWidth: 1200
  }
})

export default style