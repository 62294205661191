const style = theme => ({
  card: {
    boxShadow: 'none',
    height:250,
    width: 350,
    backgroundColor: "#00244b",
    color: "#fff",
    display: "flex",
  },
  content : {
    height:65,
  },
  action: {
    height:40
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  text: {
    height: 95,
  },
  icon: {
    fontSize:"2.5rem",
    color: "rgba(255,255,255,.8)"
  },
  btn: {
    alignContents: "center"

  },
  typography: {
    fontFamily: "sans-serif, Roboto",
    fontWeight: 300
  },
  expand: {
    display:'flex',
    alignContents:'center',
    outline: 'none',
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    padding: 0,
    color: "#fff",
    transform: 'rotate(0deg)',
    WebkitTransform: 'rotate(0deg)',
    MsTransform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    WebkitTransition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    MsTransition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    WebkitTransform: 'rotate(180deg)',
    MsTransform: 'rotate(180deg)',
  },
})

export default style