const ExpansionPanelSummaryStyle = theme => ({
    root: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
        minHeight: 56,
    },
    },
    content: {
    '&$expanded': {
        margin: '12px 0',
    },
    },
    expanded: {},
})

const ExpansionPanelDetailsStyle = theme => ({
    root: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      flexDirection: window.innerWidth > 992 ? 'row' : 'column',
      display:'block'
    },
})

const ExpansionPanelStyle = theme => ({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})

const style = theme =>({
    heading: {
      fontSize: theme.typography.pxToRem(20),
      flexBasis: '100%',
      flexShrink: 0,
      width: '100%',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(17),
      color: theme.palette.text.secondary,
    },
    details: {
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(17),
      fontWeight: 300,
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#9d002c',

      '&:hover': {
        color: '#fff',
        backgroundColor: "#89000e",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.4),0px 1px 1px 0px rgba(0,0,0,0.34),0px 1px 3px 0px rgba(0,0,0,0.32);"
      },
    },
})

export { 
    style,
    ExpansionPanelDetailsStyle,
    ExpansionPanelStyle,
    ExpansionPanelSummaryStyle,
}