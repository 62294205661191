var assenzaSinistri = process.env.PUBLIC_URL+'/media/filesSoluzioni/moduloAssenzaSinistri.pdf'
var riepilogoPrestazioni_Cyber = process.env.PUBLIC_URL+'/media/filesSoluzioni/riepilogoPrestazioni_Cyber.pdf'
var questionarioFino7_Cyber = process.env.PUBLIC_URL+'/media/filesSoluzioni/questionarioFino7.5_Cyber.pdf'
var questionarioOltre7_Cyber = process.env.PUBLIC_URL+'/media/filesSoluzioni/questionarioOltre7.5_Cyber.pdf'
var riepilogoPrestazioni_DO = process.env.PUBLIC_URL+'/media/filesSoluzioni/riepilogoPrestazioni_DO.pdf'
var questionario_DO = process.env.PUBLIC_URL+'/media/filesSoluzioni/questionario_DO.pdf'
var riepilogoPrestazioni_Mediatech = process.env.PUBLIC_URL+'/media/filesSoluzioni/riepilogoPrestazioni_Mediatech.pdf'
var questionario_Mediatech = process.env.PUBLIC_URL+'/media/filesSoluzioni/questionario_Mediatech.pdf'
var questionario_Recall_RCTO = process.env.PUBLIC_URL+'/media/filesSoluzioni/questionario_Recall.RCTO.pdf'

const data = [
  {
    title: "All Risk delle tecnologie e delle telecomunicazioni", 
    icon: 'https',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Società che operano nel settore tecnologico e delle telecomunicazioni"],
      },
      {
        title: "Copertura",
        text: ["Responsabilità civile professionale; ", "RC Prodotti + RC Inquinamento; ", "Responsabilità Cyber e Privacy"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Aviation E Droni", 
    icon: 'airplanemode_active',
    sections: [
      {
        title: "Danni All'Aereomobile",
        text: ["A chi è rivolta: ", "Aviazione Generale; ", "Aviazione da diporto o sportiva e UAV"],
      },
      {
        title: "RC Aeronautiche",
        text: ["A chi è rivolta: ", "Prodotti aeronautici; ", "Gestori aeroportuali; ", "Servizi di handling aeroportuali; ", "Organizzatori di manifestazioni aeree; ", "Paracadutisti e Associazioni paracadutistiche; "],
      },
      {
        title: "Infortuni Aeronautici In Forma Nominativa",
        text: ["A chi è rivolta: ", "Piloti; ", "Equipaggi; ", "Passeggeri; "],
      },
      {
        title: "Polizze Aeronautiche Collettive",
        text: ["A chi è rivolta: ", "Equipaggi; ", "Enti; ", "Banche; "],
      },
      {
        title: "Perdita Della Licenza Professionale",
        text: ["A chi è rivolta: ", "Piloti; ", "Equipaggi; ", "Controllori di volo"],
      },
    ]
  }, 
  {
    title: "Cauzioni - Fideiussioni", 
    icon: 'credit_card',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Aziende; ", "Professionisti"]
      },
      {
        title: "Copertura",
        text: ["Contratti per appalti pubblici e privati; ", "Contatto assimilante agi appalti; ", "Diritti Doganali; ", "Rimborso anticipato dell'I.V.A.; ", "Autorizzazioni ministeriali in genere; ", "Iscrizione all'albo gestori ambientali (ANGA); ", "Spedizione transfrontaliera di rifiuti; ", "Smaltimanto e impianti gestione rifiuti"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Cyber", 
    icon: 'usb',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Strutture Sanitarie; ", "PMI","Grande Industria; "]
      },
      {
        title: "Documentazione",
        files: [
          {
            text: "Modulo assenza sinistri",
            url: assenzaSinistri
          },
          {
            text: "Riepilogo prestazioni",
            url: riepilogoPrestazioni_Cyber
          },
          
          {
            text: "Questionario fino 7,5mil",
            url: questionarioFino7_Cyber
          },
          {
            text: "Questionario oltre 7,5mil",
            url: questionarioOltre7_Cyber
          },
        ]
      }
    ]
  }, 
  {
    title: "Contingency", 
    icon: 'euro',
    sections: [
      {
        title: "Eventi Meteorologici",
        text: ["A chi è rivolta: ", "Agricoltura; ", "Turismo; ", "Energie Alternative; ", "Produzioni Cinematografiche"],
      },
      {
        title: "Cancellazione Eventi",
        text: ["A chi è rivolta: ", "Eventi Commerciali come concerti, spettacoli e feste in piazza; ", "Eventi anche di carattere privato (Matrimoni)"],
      },
      {
        title: "Over Redemption",
        text: ["A chi è rivolta: ", "Piccola e Grande distribuzione (Assicura contro il rischio di maggiori costi (fuori budget) di una promozione di buoni di acquisto, sconti"],
      },
      {
        title: "Prize Indemnity",
        text: ["A chi è rivolta: ", "Estrazioni; ", "Lotterie (giochi di probabilità); ", "Hole in One nel gioco del golf; ", "Half Court Shot nel gioco del basket o FieldGoal Kicks nel gioco del calcio (giochi di abilità). Diffusi soprattutto ma non solo in ambito sportivo, questi giochi permettono di vincere un premio al partecipante che, grazie alla sua abilità, sia in grado di realizzare una buca con un tiro solo nel golf, un canestro da metà campo nel basket o tanti altri giochi sportivi e/o d’intrattenimento a premio"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Colpa Grave", 
    icon: 'security',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Enti Pubblici; ","Servizio Sanitario Nazionale"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "D&O", 
    icon: 'emoji_transportation_icon',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["PMI; ", "Grande Industria; "],
      },
      {
        title: "Documentazione",
        files: [
          {
            text: "Modulo assenza sinistri",
            url: assenzaSinistri
          },
          {
            text: "Riepilogo prestazioni",
            url: riepilogoPrestazioni_DO
          },
          {
            text: "Questionario",
            url: questionario_DO
          },
        ]
      }
    ]
  }, 
  
  {
    title: "Energy", 
    icon: 'power',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["ES.CO. (Società di servizi energetici)"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Infedeltà dipendenti e Atti Dolosi o Fraudolenti", 
    icon: 'supervisor_account',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Banche; ", "Società di Gestione di investimenti; ", "Compagnie assicurative e riassicurative; ", "Società di finanziamenti immobiliari; ", "Intermediari finanziari ed assicurativi; ", "Mercati azionari; ", "Camere di compensazione; ","Piattaforme regolate per finanziamenti"]
      },
      {
        title: "Copertura",
        text: ["Atti infedeli dei dipendenti; ", "Frodi documentali, informatiche o telefoniche; ", "Perdite o danni ai beni; ", "Società controllate acquistate durante il periodo di polizza; ", "Costi relativi a furti di identità aziendale; ", "Furto errato trasfermimento denaro; ", "Venir meno dei diritti di sottoiscrizione; ", "Social engineering"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Infortuni", 
    icon: 'directions_walk',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Individuali; ", "Collettive; ", "Calciatori; ", "Motociclisti; ", "Sport estremi"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Istituti di Vigilanza", 
    desc: 'Possiamo quotare fatturati fino a €7.500.000 e massimali RTC/O + RCC fino a €5.000.000',
    icon: 'apartment',
    sections: [
      {
        title: "Copertura",
        text: ["Responsabilità civile verso terzi; ", "Responsabilità civile verso i prestatori di lavoro; ", "Responsabilità civile contrattuale"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Mediatech", 
    icon: 'share',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Società di software; ", "Integratori di sistemi; ", "Outsourcing della tecnologia; ", "Consulenza tecnologica; ", "Servizi internet; ", "Fornitori hardware/software; ", "Servizi alle imprese combinati"],
      },
      {
        title: "Documentazione",
        files: [
          {
            text: "Modulo assenza sinistri",
            url: assenzaSinistri
          },
          {
            text: "Riepilogo prestazioni",
            url: riepilogoPrestazioni_Mediatech
          },
          {
            text: "Questionario",
            url: questionario_Mediatech
          },
        ]
      }
    ]
  }, 
  {
    title: "Med Mal", 
    icon: 'local_hospital',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Cliniche private con ostetricia; ", "Cliniche private senza ostetricia"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Professionali Miscellanei", 
    icon: 'business-center',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Aziende ortofrutticole; ", "Aziende di produzione; ", "Aziende gestione fondi; ", "Società di costruzione"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "Professional Sports & Events", 
    icon: 'sports_basketball',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Team; ", "Federazioni; ", "Leghe; ", "Associazioni"],
      },
      {
        title: "Soluzioni Assicurative",
        text: ["Polizza infortuni obbligatoria; ", "Polizza infortuni patrimoniale; ", "RSM; ", "Polizze “ingaggi” e “fine carriera”; ", "Polizza infortuni individuale; ", "Prize indemnity"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      },
    ]
  }, 
  {
    title: "RCT/O", 
    icon: 'account_balance',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Società di costruzione; ", "Alberghi; ", "Aziende che trattano Energia; ", "Aziende Chimiche; ", "Aziende di produzione Alimentare; ", "Società di Trasporti"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      },
      {
        title: "Documentazione",
        files: [
          {
            text: "Questionario",
            url: questionario_Recall_RCTO
          },
        ]
      }
    ]
  }, 
  {
    title: "RCT/O - Patrimoniale Enti Pubblici", 
    icon: 'public',
    sections: [
      {
        title: "Copertura",
        text: ["Responsabilità Civile Terzi; ", "Responsabilità Civile prestatori d’opera; ", "Patrimoniale; ", "Colpa Grave Dirigenti e Dipendenti di Enti Pubblici"],
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  }, 
  {
    title: "RECALL Del Prodotto", 
    desc: 'La RECALL del Prodotto fornisce copertura per le spese associate al richiamo di un prodotto dal mercato.',
    icon: 'list_alt',
    sections: [
      {
        title: "Richiamo di un prodotto dal mercato",
        text: ["Copertura per: ", "Spese di richiamo (ad es. Smaltimento e sostituzione)p; ", "Spese di pre-richiamop; ", "Spese di richiamo di terzip; ", "Interruzione dell'attivitàp; ", "Perdita di profitti / ricavi lordip; ", "Contaminazione accidentale", "Riabilitazione del prodottop; ", "Aumento dei costi di lavoro a seguito di un richiamop; ", "Gestione delle crisi / Costi di consulenza"]
      },
      {
        title: "Danno alla reputazione",
        text: ["Copertura per: ", "Negligenza dei dipendenti; ", "Disgrazia delle celebritàp; ", "Disgrazia del fornitorep; ", "Problemi di qualità del prodottop; ", "Problemi di contraffazione"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      },
      {
        title: "Documentazione",
        files: [
          {
            text: "Questionario",
            url: questionario_Recall_RCTO
          },
        ]
      }
    ]
  }, 
  {
    title: "Responsabilità Ambientale", 
    icon: 'eco',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Aziende produttive; ", "Aziende artigianali; ", "Aziende del commercio; ", "Servizi in genere; ", "Aziende della logistica; ", "Aziende delle Multiutilitiesp; ", "Aziende che operano presso siti terzip; ", "Aziende che adottano il trasporto delle proprie merci a vettori terzi"]
      },
      {
        title: "Copertura",
        text: ["Danni riconducibili alla responsabilità del Committente"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Responsabilità Civile per Offerta Pubblica di Titoli", 
    icon: 'subtitles',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Banche; ", "Società di Gestione di investimenti; ", "Compagnie assicurative e riassicurative; ", "Società di finanziamenti immobiliari; ", "Intermediari finanziari ed assicurativi; ", "Mercati azionari; ", "Camere di compensazione; ", "Piattaforme regolate per finanziamenti"]
      },
      {
        title: "Copertura",
        text: ["Richieste di risarcimento avanzate contro le persone, la società, l'azionista di controllo o vendite; ", "Emissioni di titoli successivi; ", "Costi regolatori e per indagini interne; ", "Costi inerenti alla violazione della normativa di protezione dei dati personali o della reputazione; ", "Costi di mitigazione; ", "Costi di consulenza per la regolamentazione dei titoli olandesi; ", "La nostra polizza prevede una reintegrazione automatica e gratuita del massimale per perdite non indennizabili della società"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Responsabilità Civile Professionale", 
    icon: 'work_outline',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Professioni Regolamentate e Non Regolamentate con esigenza di massimali elevati e/o normativi specifici"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Sperimentazioni Cliniche", 
    icon: 'opacity',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Università ", "Ospedali"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
  {
    title: "Tutela", 
    icon: 'gavel',
    sections: [
      {
        title: "A chi è rivolta",
        text: ["Professionisti; ", "Società"]
      },
      {
        title: "Maggiori informazioni",
        text: "Contattaci all'indirizzo quotazioni@easyunderwriting.it"
      }
    ]
  },
]

export default data
