import React from 'react'
import Fade from "react-reveal/Fade";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import Contact from 'components/Contact/Contact';
import Gallery from 'react-grid-gallery';
import data from './TabellariData'
import styles from './style.module.scss'

export default function Tabellari() {
  return (
    <React.Fragment>
      <Navbar />
      <div className='mt-md-5 py-4'>
        <Fade force>
          <h2 className="h2-responsive text-center py-4 py-md-5 my-3">Tabellari</h2>
          <div className={styles.container+' mx-auto container'}>
            <Gallery images={data} rowHeight={300} margin={5} enableImageSelection={false} backdropClosesModal={true}/>
          </div>
        </Fade>
      </div>
      <Contact />
      <Footer />
    </React.Fragment>
  )
}