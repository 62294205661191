import React from "react";

export default function Contact() {
  return (
    <section id="contact" className="reverse" style={{backgroundImage: (window.innerWidth<768 ? "url(/media/bg_mobile_600x900.jpg)": "url(/media/bg.jpg)")}}>
      <div className="py-5 rgba-black-strong reverse">
        <div className="wow animated fadeIn">
          <h2 className="h1 h2-responsive text-white pt-4 pt-md-5 pb-3 text-center">Contattaci</h2>
        </div>

        <div className="row justify-content-around align-items-center py-5 mx-3 wow animated fadeIn">

          <div className="col-12 col-md-5 text-white p-3 mb-5 mb-lg-0">
            <p className="display-4 font-weight-bold d-none d-md-block">Vuoi diventare nostro partner?</p>
            <p className="h1 font-weight-bold d-block d-md-none">Vuoi diventare nostro partner?</p>
            <hr style={{width:'100%',borderTop: '1px solid #fff'}} />
            <p className="h5 font-weight-light">Se sei un Intermediario regolarmente iscritto al RUI, contatta il nostro General Manager per fissare un incontro presso la tua sede</p>
          </div>

          <div style={{width:'355px', minWidth: '355px'}} className="col col-md-5 card px-2 px-md-3 px-xl-5 py-3">
            <div className="w-100 py-3">
              <div className="row">
                <div className="col">
                  <p className="w-100 m-0">
                    <strong>Managing Director</strong>
                    <br />Raffaella Salerno
                  </p>
                </div>
              </div>
              <hr style={{width:"100%"}}/>
            </div>
            <div className="py-3 w-100">
              <p>
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">email</i>
                <span className='pr-0 pl-md-2'>quotazioni@easyunderwriting.it</span>
              </p>
            </div>

            <div className='py-3 w-100'>
              <p className="w-100"><strong>Sedi Amministrative</strong></p>

              <p> 
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">room</i>
                <span className='pr-0 pl-md-2'>Piazza Madonna in campagna 7, 20021 Bollate (Mi)</span>
              </p>

              <p> 
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">room</i>
                <span className='pr-0 pl-md-2'>Piazza Federico Fellini 1-2, 00065 Fiano Romano (RM)</span>
              </p>

              <p>
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">call</i>
                <span className='pr-0 pl-md-2'>0765.482087</span>
              </p>

              <p>
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">email</i>
                <span className='pr-0 pl-md-2'>amministrazione@easyunderwriting.it</span>
              </p>
            </div>
            <div className='py-3 w-100'>
              <p className="w-100"><strong>Sedi Operative</strong></p>

              <p> 
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">room</i>
                <span className='pr-0 pl-md-2'>Viale rimembranze 43, 20045 Lainate (Mi)</span>
              </p>
              <p> 
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">room</i>
                <span className='pr-0 pl-md-2'>Via 1 Maggio 10, 20020 Solaro (Mi)</span>
              </p>
              <p> 
                <i className="material-icons-outlined indigo-text p-0 text-center align-middle">room</i>
                <span className='pr-0 pl-md-2'>Piazza Federico Fellini 1-2, 00065 Fiano Romano (RM)</span>
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}