import React, {useEffect} from 'react'
import Navbar from "components/Navbar/Navbar"
import Footer from "components/Footer/Footer"
import Contact from 'components/Contact/Contact'
import CardCollapse from "components/CardCollapsable/CardCollapse/CardCollapse"
import CollapseDiv from "components/CardCollapsable/CollapseDiv/CollapseDiv"
import Grid from '@material-ui/core/Grid';
import Fade from "react-reveal/Fade";
import data from './SolutionsData';

export default function Solutions() {
  let cols=Math.min(Math.trunc(window.innerWidth/350 ), 4)
  const [isOpen,setIsOpen] = React.useState(null)
  const [grid, setGrid] = React.useState({
    col: cols, 
    row: Math.trunc((data.length/cols))+(data.length%cols ? 1 : 0), 
    size: window.innerWidth>1404 ? "lg" :  (window.innerWidth>1053 ? "md" : "sm")
  })

  function handleClick(i) {
    if (i===isOpen)
      setIsOpen(null);
    else 
      setIsOpen(i);
  }
  
  useEffect(() => {
    const handleResize = () => {
      if ((window.innerWidth>1404 && grid.size !== "lg") || (window.innerWidth>1053 && grid.size !== "md") || (window.innerWidth<=1053 && grid.size !== "sm"))  {
        var col=Math.min(Math.trunc(window.innerWidth/350 ), 4)
        setGrid(
          {
            col: col, 
            row :Math.trunc((data.length/col))+(data.length%col ? 1 : 0), 
            size: window.innerWidth>1404 ? "lg" :  (window.innerWidth>1053 ? "md" : "sm")
          }
        )
      } 
    }
    window.addEventListener('resize', handleResize)
  }, [grid.size])


  const createCard = (i ,j, t) => {
    return( 
      <div 
        key={t+"Card"} 
        className={'col-auto p-0'+ ( (i < grid.col-1) ? ' border-r' : '')+(j < grid.row-1 ? ' border-b' : '')}
      >
        <Fade delay={t*100} force>
          <CardCollapse 
            isOpen={t===isOpen ? true : false}
            handleClick={() => handleClick(t)} 
            title={data[t-1].title} 
            icon={data[t-1].icon} 
          />
        </Fade>
      </div>
    )
  }


  const createCollapse = (t) => {
    return (
      <CollapseDiv 
        key={t+"Collapse"} 
        isOpen={t===isOpen ? true : false}
        title={data[t-1].title} 
        desc={data[t-1].desc ? data[t-1].desc : null} 
        sections={data[t-1].sections}
      />
    )
  }

  const createRow = (j) => {
    var t = (j*grid.col)+1
    var cards = []
    var collapse = []
    var columns = j<grid.row-1 ? grid.col : (data.length%grid.col ? data.length%grid.col : grid.col)

    for (var i = 0; i < columns; i++) {
      cards.push(createCard(i, j , t))
      collapse.push(createCollapse(t))
      t += 1
    }

    var row = [
      <Grid 
        key={j+"Row"} 
        className='row justify-contents-left mx-auto' 
        style={{width: (351*grid.col)+"px"}}
      >
        {cards}
      </Grid>, collapse
    ]

    return (row)
  }

  const createGrid = () => {
    var comp = []
    for (var j = 0; j < grid.row; j++) {
      comp.push(createRow(j))
    }
    return (comp)
  }

  return (
  <React.Fragment>
    <Navbar />
    <div 
      className='mt-md-5 py-4' 
      style={{backgroundColor:"#00244b"}}
    >
      <Fade force>
        <h2 className="h2-responsive text-center text-white py-4 py-md-5 my-3">Soluzioni</h2>
      </Fade>
      {createGrid()}
    </div>
    <Contact />
    <Footer />
  </React.Fragment>
  
  )
}